import styled from 'styled-components';

import { getDimension } from 'styled/helpers/getDimension';

export const TitleContainer = styled.div`
  display: flex;

  // remove margin from first child (h2)
  > *:first-child {
    margin: 0;
  }

  > * {
    padding-right: ${getDimension('size4')};
  }

  // remove padding right from last child
  > *:last-child {
    padding-right: 0;
  }
`;
