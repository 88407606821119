import { useGatsbyImage } from 'hooks/useGatsbyImage';

import { Seo } from 'components/Seo';
import { Layout } from 'components/Layout';
import { Container, HeroContainer, Section, SectionImage } from 'components/Container';
import { MemberTitle } from 'components/Team';

import { boardOfDirectors, executiveProfiles } from 'page-data/executiveTeam';

import type { FC, ReactNode } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';
import type { TeamMember } from 'page-data/executiveTeam';

export const Team: FC<LocalizedPageProps> = () => {
  const images: { [key in TeamMember]: ReactNode } = {
    'Doug Morris': useGatsbyImage({ name: 'team/doug-morris', alt: 'Doug Morris' }),
    'Jessica Goodall': useGatsbyImage({ name: 'team/jessica-goodall', alt: 'Jessica Goodall' }),
    'Prashant Mohan': useGatsbyImage({ name: 'team/prashant-mohan', alt: 'Prashant Mohan' }),
    'Thorsten Böttger': useGatsbyImage({ name: 'team/thorsten-bottger', alt: 'Thorsten Böttger' }),
    'Ben Clendon': useGatsbyImage({ name: 'team/ben-clendon', alt: 'Ben Clendon' }),
    'Andrew Bird': useGatsbyImage({ name: 'team/andrew-bird', alt: 'Andrew Bird' }),
    'Tony Ryburn': useGatsbyImage({ name: 'team/tony-ryburn', alt: 'Tony Ryburn' }),
    'John Addis': useGatsbyImage({ name: 'team/john-addis', alt: 'John Addis' }),
    'Nigel Allfrey': useGatsbyImage({ name: 'team/nigel-allfrey', alt: 'Nigel Allfrey' }),
  };

  return (
    <Layout>
      <Seo />

      {/* Heading */}
      <HeroContainer background="cream">
        <Section>
          <h1>Our leadership</h1>
        </Section>
      </HeroContainer>

      <Container background="white" bottomPadding={1}>
        <h2>Executive team</h2>
      </Container>
      {executiveProfiles.map(({ name, linkedin, twitter, title, description }) => (
        <Container wrapMobile="hard" columns={3} gap={10} key={name}>
          <SectionImage image={images[name]} borderRadius={false} boxShadow={false} />
          <Section span={2}>
            <MemberTitle name={name} linkedin={linkedin} twitter={twitter} />
            <h4>{title}</h4>
            <p>{description}</p>
          </Section>
        </Container>
      ))}

      <Container background="callout" skipColoration>
        <h2>Non-executive team</h2>
      </Container>
      {boardOfDirectors.map(({ name, linkedin, twitter, title, description }) => (
        <Container wrapMobile="hard" columns={3} key={name}>
          <Section span={2}>
            <MemberTitle name={name} linkedin={linkedin} twitter={twitter} />
            <h4>{title}</h4>
            <p>{description}</p>
          </Section>
          <SectionImage image={images[name]} firstOnMobile borderRadius={false} boxShadow={false} />
        </Container>
      ))}
    </Layout>
  );
};

export default Team;
